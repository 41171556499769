import React, { useRef, useState } from "react";
import "./App.css";
import { useOnScreen } from "./useOnScreen.js";
import emailjs from "@emailjs/browser";
import { 
  Button,
  Dialog, 
  DialogActions,
  DialogContent,
  DialogContentText
} from "@mui/material";

const RSVP = ({ setShowLoading }) => {
  const [submitted, submit] = useState(false);
  const [showDialog, toggleDialog] = useState(false)
  const rsvpRef = useRef(null);
  const isOnScreen = useOnScreen(rsvpRef);
  const form = useRef(null);

  const sendEmail = () => {
    setShowLoading(true)
    emailjs
      .sendForm("service_zzx4fr7", "template_j87bj1v", form.current, {
        publicKey: "gH-AFwjyvKhP_aVfg",
      })
      .then(
        () => {
          submit(true)
          setShowLoading(false)
        },
        (error) => {
          console.log("ERROR: ", error);
          toggleDialog(true)
          setShowLoading(false)
        }
      );
  };

  const content = () => {
    if (submitted) {
      return (
        <div className="section-container">
          <h2>Rsvp</h2>
          <p >
            Thank you for your RSVP confirmation! 
          </p>
          <p className="font-corinthia font-light text-4xl">
            See you on our wedding day!
            </p>
        </div>
      );
    } else {
      return (
        <div className="section-container">
          <h2>Rsvp</h2>
          <p>
            As we are preparing our wedding, we highly appreciate if you could
            confirm your pressence on the day of our wedding. Kindly fill up
            this form and we will get back to you for further details
          </p>
          <p
            style={{
              marginTop: 32,
              marginBottom: 32,
            }}
            className="font-corinthia font-light text-4xl"
          >
            Please respond with your name/s and contact number
          </p>
          <form ref={form}>
            <input type="name" name="names" placeholder="Name/s of Attendee/s" />
            <input type="tel" name="contactNumber" placeholder="Contact Number" />
            <input
              className="send-button"
              type="button"
              value="Send"
              onClick={() =>
                sendEmail()
              }
            />
          </form>
          <p style={{
            marginTop: 8,
            backgroundColor: 'white'
            }}><b>*Head count given to the attendees is strictly followed*</b></p>
        </div>
      );
    }
  };

  return (
    <div
      ref={rsvpRef}
      className={"rsvp-section" + (isOnScreen ? " visible" : "")}
    >
      <Dialog
        open={showDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            There was a problem encountered. Please try again or contact administrator
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {toggleDialog(false)}} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {content()}
    </div>
  );
};

export default RSVP;
