import React, { useEffect, useRef } from "react";
import "./App.css";
import { useOnScreen } from "./useOnScreen.js";


const Welcome = () => {
    const welcomeRef = useRef(null);
    const isOnScreen = useOnScreen(welcomeRef)

    return (
        <div ref={welcomeRef} className={"welcome-section" + (isOnScreen ? " visible" : "")}>
            <div className="welcome-section-container">
                <h2>We do.</h2>
                <p style={{
                    marginTop: 16,
                }}>Together with our families</p>
                <h3>Hony & Dan</h3>
                <p>Please join us as we celebrate the joyous occasion of our marriage</p>
                <br/>
                <p>on</p>
                <p className="font-bodoni text-2xl" style={{
                    marginTop: 16,
                }}>May 18, 2024 | Saturday | 3:30 PM</p>
                <p style={{
                    marginTop: 16,
                }}>at</p>
                <p className="font-corinthia text-4xl" style={{
                    marginTop: 16
                }}>The Mango Farm Events Place</p>
                <p className="font-bodoni text-base">Kingsville Court, Cainta, Rizal</p>
            </div>
        </div>
    )
}

export default Welcome;