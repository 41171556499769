import React, { useRef } from "react";
import "./App.css";
import { useOnScreen } from "./useOnScreen.js";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineOppositeContent,
  TimelineDot
} from "@mui/lab";
import {
  GrassOutlined,
  VillaOutlined,
  LocalBarOutlined,
  NightlifeOutlined,
} from "@mui/icons-material";
import { ThemeProvider, createTheme } from "@mui/material";

const timelineTheme = createTheme({
  components: {
    TimelineConnector: {
      stylesOverrides: {
        root: {
          color: "#800020",
        },
      },
    },
  },
  palette: {
    primary: {
        main: '#800020',
    },
    secondary: {
        main: '#F2DADF',
    }
  }
});

const Programme = () => {
  const programmeRef = useRef(null);
  const isOnScreen = useOnScreen(programmeRef);
  return (
    <div
      ref={programmeRef}
      className={"programme-section" + (isOnScreen ? " visible" : "")}
    >
      <div className="programme-section-container">
        <ThemeProvider theme={timelineTheme}>
          <Timeline position="alternate" className="timeline">
            <h2>Programme</h2>
            <TimelineItem>
              <TimelineOppositeContent
                sx={{ m: "auto 0" }}
                align="right"
              >
                <h4>3:30 PM</h4>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot color="primary">
                  <GrassOutlined color="secondary"/>
                </TimelineDot>
                <TimelineConnector style={{ color: '#800020' }}/>
              </TimelineSeparator>
              <TimelineContent sx={{ m: "auto 0" }}>
                <h3>Ceremony</h3>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineOppositeContent sx={{ m: "auto 0" }}>
                <h4>4:30 PM</h4>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineConnector color="primary"/>
                <TimelineDot color="primary">
                  <LocalBarOutlined color="secondary"/>
                </TimelineDot>
                <TimelineConnector color="primary"/>
              </TimelineSeparator>
              <TimelineContent sx={{ m: "auto 0" }}>
                <h3>
                  Cocktails
                </h3>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineOppositeContent sx={{ m: "auto 0" }}>
                <h4>5:15 PM</h4>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineConnector color="primary"/>
                <TimelineDot color="primary">
                  <VillaOutlined color="secondary"/>
                </TimelineDot>
                <TimelineConnector color="primary"/>
              </TimelineSeparator>
              <TimelineContent sx={{ m: "auto 0" }}>
                <h3>
                  Reception
                </h3>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineOppositeContent sx={{ m: "auto 0" }}>
                <h4>9:00 PM</h4>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineConnector color="primary"/>
                <TimelineDot color="primary">
                  <NightlifeOutlined color="secondary"/>
                </TimelineDot>
              </TimelineSeparator>
              <TimelineContent sx={{ m: "auto 0" }}>
                <h3>After Party</h3>
              </TimelineContent>
            </TimelineItem>
            <p>Please share your photos and videos with the hashtag</p>
            <h5>#DANextchapterwithHONY</h5>
          </Timeline>
        </ThemeProvider>
      </div>
    </div>
  );
};

export default Programme;
