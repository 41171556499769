import React from "react";
import Welcome from "./Welcome.js";
import Entourage from "./Entourage.js";
import Dresscode from "./Dresscode.js";
import Location from "./Location.js";
import GiftGuide from "./GiftGuide.js";
import RSVP from "./RSVP.js";
import Programme from "./Programme.js";
import FAQ from "./FAQ.js";

function getItem(selected, setShowLoading) {
  switch (selected) {
    case 0:
      return <Welcome />;
    case 1:
      return <Entourage />;
    case 2:
      return <Dresscode />;
    case 3:
      return <Location />;
    case 4:
      return <GiftGuide />;
    case 5:
      return <RSVP setShowLoading={setShowLoading}/>;
    case 6:
      return <Programme />
    case 5:
      return <FAQ />;
  }
}

export default getItem;
