import React, { useRef } from "react";
import "./App.css";
import { useOnScreen } from "./useOnScreen.js";

const Dresscode = () => {
  const dressCodeRef = useRef(null);
  const isOnScreen = useOnScreen(dressCodeRef);
  return (
    <div ref={dressCodeRef} className={"dresscode-section" + (isOnScreen ? " visible" : "")}>
      <div className="dresscode-section-container">
        <h2>Dress Code</h2>
        <div className="dresscode-container">
          <div className="dresscode-men">
            <h3>Gents</h3>
            <h5>(Semi-formal)</h5>
            <p>Black coat/suit (optional)</p>
            <p>Button down long sleeves</p>
            <p>Black/Khaki Slacks</p>
          </div>
          <div className="dresscode-divider">
          </div>
          <div className="dresscode-women">
            <h3>Ladies</h3>
            <p>Long Dress</p>
            <p>Long Gown</p>
          </div>
        </div>
        <h3>Color Palette</h3>
        <div className="dresscode-color-container">
          <div className="dresscode-burgundy" />
          <div className="dresscode-dusty-pink" />
          <div className="dresscode-peach" />
        </div>
        <p style={{
            backgroundColor: 'white',
          }}>We kindly encourage our guests to wear these colors on our special day</p>
      </div>
    </div>
  );
};

export default Dresscode;
