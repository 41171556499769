import React from "react";
import { useEffect, useState } from "react";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import mapImage from './mapImage.png';
import "./Map.css";

const libraries = ["place/The+Mango+Farm+Events+Place"];
const mapContainerStyle = {
  height: "50vh",
  width: "auto",
};

const center = {
  lat: 14.61198435450637, 
  lng: 121.12547936441797
};

const Map = () => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyA81suWelIi4itkKRThU8yocI7P4DX-U9g",
    libraries,
  });

  const [marker, setMarker] = useState();
  useEffect(() => {
    if (isLoaded) {
      setMarker(<Marker position={center} />)
    }
  }, [isLoaded]);

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading maps</div>;
  }

  return (
    <div>
      <img src={mapImage} className="map"/>
    </div>
  );
};

export default Map;
