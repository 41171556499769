import React from "react";

function App() {
    return(
        <div>
            HD Invitations
        </div>
    )
}

export default App;