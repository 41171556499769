import React from "react";
import "./App.css";

const FAQ = () => {
    return (
        <div className="section">
        <div className="section-container">
          <h2>FAQ</h2>
        </div>
      </div>
    )
}

export default FAQ;