import React from "react";
import "./NavBar.css";

const NavBar = ({setSelectedItem}) => {
    return(
        <div className="navbar-container">
            <button onClick={() => setSelectedItem(1)}>Entourage</button>
            <button onClick={() => setSelectedItem(2)}>Dress code</button>
            <button onClick={() => setSelectedItem(3)}>Location</button>
            <button onClick={() => setSelectedItem(4)}>Gift Guide</button>
            <button onClick={() => setSelectedItem(5)}>RSVP</button>
            <button onClick={() => setSelectedItem(6)}>Programme</button>
        </div>
    )
}

export default NavBar;