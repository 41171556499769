import React, { useRef } from "react";
import "./App.css";
import "./Entourage.css";
import { useOnScreen } from "./useOnScreen.js";

const Entourage = () => {
  const entourageRef = useRef(null);
  const isOnScreen = useOnScreen(entourageRef);
  return (
    <div
      ref={entourageRef}
      className={"entourage-section" + (isOnScreen ? " visible" : "")}
    >
      <div className="entourage-section-container">
      <h2>The Entourage</h2>
        <div className="parents-container">
          <div className="parents-container-groom">
            <h3>Parents of Groom</h3>
            <h4>Mr. Danilo Chua</h4>
            <h4>Mrs. Maria Cecilia Chua</h4>
          </div>
          <div className="parents-container-bride">
            <h3>Parents of Bride</h3>
            <h4>Mr. Jeric Pasiliao - Son of </h4>
            <h4>Mr. Florencio Pasiliao</h4>
            <h4>Mrs. Salustiana Pasiliao</h4>
          </div>
        </div>
        <div className="sponsors-container">
          <h3>Principal Sponsors</h3>
          <table>
            <tr>
              <td>Engr. Ernest D. Yuyek</td>
              <td>Ms. Marie Grace Macatangay</td>
            </tr>
            <tr>
              <td>Engr. Hubert Neal Liwanag</td>
              <td>Mrs. Imelda Liwanag</td>
            </tr>
            <tr>
              <td>Dr. Philip Pasiliao</td>
              <td>Ms. Regina Gonzales</td>
            </tr>
            <tr>
              <td>Mstr. Sgt. Eriz Limpiado</td>
              <td>Mrs. Sarah Jane Limpiado</td>
            </tr>
            <tr>
              <td>Dr. Denis Pasiliao</td>
              <td>Dra. Chriselle Pasiliao</td>
            </tr>
            <tr>
              <td>Dr. Eduardo Viola</td>
              <td>Dra. Brenda Viola</td>
            </tr>
          </table>
        </div>
        <div className="righthand-container">
            <h3>Best Man</h3>
            <h4>Mr. Whencie Delfino</h4>
            <h3>Man of Honor</h3>
            <h4>Mr. Julian Bautista</h4>
        </div>
        <div className="ushers-container">
          <table>
            <tr>
              <th>Groomsmen</th>
              <th>Bridesmaid</th>
            </tr>
            <tr>
              <td>Mr. Kent Jonathan Wan</td>
              <td>Ms. Noranisa Usman</td>
            </tr>
            <tr>
              <td>Mr. Vincent Ivan Chua</td>
              <td>Ms. Queen Silvosa</td>
            </tr>
            <tr>
              <td>Mr. Jasper Ferber</td>
              <td>Ms. Charlotte Magnifico</td>
            </tr>
          </table>
        </div>
        <div className="kids-container">
          <div className="kids-container-ring">
            <h3>Ring Bearer</h3>
            <h4>Seth Kyden Gannaban</h4>
          </div>
          <div className="kids-container-flower">
            <h3>Flowergirls</h3>
            <h4>Daniella Yasmine Chua</h4>
            <h4>Scarlette Amelie Cruz</h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Entourage;
