import React, { useRef } from "react";
import "./App.css";
import { useOnScreen } from "./useOnScreen.js";

const GiftGuide = () => {
  const giftGuideRef = useRef(null);
  const isOnScreen = useOnScreen(giftGuideRef);
    return (
        <div ref={giftGuideRef} className={"giftguide-section" + (isOnScreen ? " visible" : "")}>
        <div className="giftguide-section-container">
          <h1>Wishing Well</h1>
          <p>
            With all that we have, we've been truly blessed. Your prayers and
            presence are all that we request. But if you desire to give
            nonetheless, a <b>Monetary Gift</b> is one we suggest.
          </p>
          <h3>with love</h3>
          <h2><b>Hony & Dan</b></h2>
        </div>
      </div>
    )
}

export default GiftGuide;