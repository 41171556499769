import "./App.css";
import Welcome from "./Welcome.js";
import Entourage from "./Entourage.js";
import Dresscode from "./Dresscode.js";
import Location from "./Location.js";
import GiftGuide from "./GiftGuide.js";
import RSVP from "./RSVP.js";
import NavBar from "./NavBar.js";
import useWindowDimensions from "./windowDimension.js";
import getItem from "./ItemSelector.js";
import { useState, useEffect } from "react";
import Programme from "./Programme.js";
import { CircularProgress, Dialog, ThemeProvider, createTheme } from "@mui/material";

function App() {
  const { height, width } = useWindowDimensions();
  const [children, setChildren] = useState();
  const [selectedItem, setSelectedItem] = useState(0);
  const [showLoading, setShowLoading] = useState(false);

  const dialogTheme = createTheme({
    palette: {
      primary: {
        main: "#800020",
      },
      secondary: {
        main: "#F2DADF",
      },
    },
  });

  useEffect(() => {
    setChildren(
      width > 700 ? (
        <div className="details-container">
          <NavBar setSelectedItem={(item) => setSelectedItem(item)} />
          {getItem(selectedItem, setShowLoading)}
        </div>
      ) : (
        <div className="details-container">
          <Welcome />
          <Entourage />
          <Dresscode />
          <Location />
          <GiftGuide />
          <RSVP setShowLoading={setShowLoading}/>
          <Programme />
        </div>
      )
    );
  }, [width, selectedItem]);

  useEffect(() => {
    document.title = "Hony And Dan Wedding Invitation";
  }, []);
  return (
    <div className="App">
      <div className="banner">
        <ThemeProvider theme={dialogTheme}>
          <Dialog
            hideBackdrop={true}
            open={showLoading}
            PaperProps={{
              elevation: 0
            }}
            sx={{
              //You can copy the code below in your theme
              background: "#00000050",
              "& .MuiPaper-root": {
                background: "00000000",
              },
              "& .MuiBackdrop-root": {
                backgroundColor: "transparent", // Try to remove this to see the result
              },
            }}
          >
            <CircularProgress color="primary" />
          </Dialog>
        </ThemeProvider>
        <button onClick={() => setSelectedItem(0)}>
          <h1>H | D</h1>
        </button>
        <h2>May 18, 2024 | 3:30 PM</h2>
      </div>
      {children}
    </div>
  );
}

export default App;
