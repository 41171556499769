import React, { useRef } from "react";
import "./App.css";
import Map from "./Map.js";
import { useOnScreen } from "./useOnScreen.js";

const Location = () => {
  const locationRef = useRef(null);
  const isOnScreen = useOnScreen(locationRef);
    return (
        <div ref={locationRef} className={"location-section" + (isOnScreen ? " visible" : "")}>
        <div className="section-container">
          <h2>Location</h2>
          <div className="map-container">
            <h3>The Mango Farm Events Place</h3>
            <Map />
          </div>
          <button
            onClick={() => {
              window.open(
                "https://waze.com/ul/hwdw5hk0cw",
                "_blank",
                "noreferrer"
              );
            }}
          >
            Click me for Waze
          </button>
          <p style={{
            backgroundColor: 'white',
          }}>
            The whole ceremony and program will happen inside The Mango Farm
            Events Place
          </p>
        </div>
      </div>
    );
};

export default Location;